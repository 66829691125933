import "./formStyle.css";
import { useState, useRef, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import TopNavbar from "../../components/navbar/TopNavbar";
import Third_party from "../../components/button/Third_party";
import {getAuth, sendSignInLinkToEmail, signInWithEmailAndPassword} from "firebase/auth";
import { useTranslation } from "react-i18next";

// export default function LoginPage({setRandomCode, randomCode}) {
export default function LoginPage() {
    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language === "ar");
    const auth = getAuth();
    const navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();
    const [errorMsg, setErrorMsg] = useState(false);

    // useEffect(() => {
    //     setRandomCode(Math.floor(Math.random() * (999999 - 100000) + 100000));
    // }, [])

    // function handleSubmit(e) {
    //     e.preventDefault();
    //     console.log(randomCode);
    //     navigate("/code");
    // }
    const handleSubmit = (event) => {

        event.preventDefault()
        // setIsPending(true);

        signInWithEmailAndPassword(auth,emailRef.current.value, passwordRef.current.value).then((res) => {
            res.user.getIdToken().then(res => console.log(res))
            navigate("/");
        }).catch((e) => {
            // setError(e.message);
            // setIsPending(false);
            // console.log(e.code);
            switch(e.code) {
                case 'auth/user-not-found':
                      setErrorMsg(t('alertMsg.email_not_exist'))
                      break;
                case 'auth/wrong-password':
                      setErrorMsg(t("alertMsg.password_wrong"))
                      break;
                default :
                setErrorMsg(e.message);
             }
        });
    }
    // const actionCodeSetting = {
    //     url: "localhost:3000/login",
    //     handleCodeInApp: true
    // }
    // function handleSubmit(e) {
    //     e.preventDefault();
    //     sendSignInLinkToEmail(auth, emailRef.current.value, actionCodeSetting)
    //     .then(() => {
    //         window.localStorage.setItem('emailForSignIn', emailRef.current.value);
    //         console.log("done")
    //     })
    //     .catch((error) => {
    //         const errorCode = error.code;
    //         const errorMessage = error.message;
    //         console.log(errorCode);
    //         console.log(errorMessage);
    //     });
    // }

    return(
        <div className="auth-page">
            <div className="header-padding">
                <div className="dark-header">
                    <TopNavbar mode="navbar-dark-mode"/>

                    <div className="auth-form-content">
                        <div className="auth-form">
                            <div className="content">
                                <div>
                                    <h1 className={`form-title ${arabicLang ? "dubai-bold" : "main-title"}`} style={arabicLang ? {maxWidth: "90%"} : {}}>{t("page.auth.title.login")}</h1>
                                </div>
                                <Third_party setErrorMsg={setErrorMsg} />
                                <div>
                                    <p className={`form-desc ${arabicLang ? "ge-two-light" : "main-title"}`}>{t("page.auth.desc.login")}</p>
                                </div>

                                {errorMsg && <span className={`errorMsg ${i18n.language == "ar" ? "ge-two-light" : "para-regular-font"}`}>{errorMsg}</span>}
                                <form className="group-form-content" onSubmit={handleSubmit}>
                                    <input ref={emailRef} name="email" type="text" className="auth-input" placeholder={t("input.placeholder.email")} style={arabicLang ? {fontFamily: "ge-two-bold"} : {fontFamily: "gliroy-semibold"}} required/>
                                    <input ref={passwordRef} name="password" type="password" className="auth-input" placeholder={t("input.placeholder.password")} style={arabicLang ? {fontFamily: "ge-two-bold"} : {fontFamily: "gliroy-semibold"}} required/>
                                    <button className="submit-btn" style={arabicLang ? {fontFamily: "dubai-bold"} : {fontFamily: "gliroy-semibold"}}>{t("button.continue")}</button>
                                </form>

                                <div><span  className="bottom-hint" style={arabicLang ? {fontFamily: "ge-two-light"} : {fontFamily: "gliroy-semibold"}}>{t("page.auth.hint.login")}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}