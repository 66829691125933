import "./btnStyle.css";
import { Link } from "react-router-dom";

export default function SignUp({t, arabicLang, mode}) {
  return <div>
      <Link to="/signup">
        <button className={`signup-btn ${arabicLang ? "dubai-bold" : "hint-bold-font"}`} style={mode === "navbar-dark-mode" ? {backgroundColor: "transparent", border: "1px solid #FFFFFF"} : {}}>{t("button.sign_up")}</button>
      </Link>
  </div>;
}
