import "./cardStyle.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function AttorneyCard({imgSrc, attorneyName, attorneyInfo1, attorneyInfo2}) {

    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language === "ar");

  return (
    <div className="attorney-card">
      <div className="img-content">
        <img src={imgSrc} alt="attorney" style={arabicLang ? {transform: "scale(-1, 1)"} : {}}/>
        <div className="overlay">
            <Link to="/details" className={`attorney-name ${arabicLang ? "dubai-bold" : "main-title"}`}>{attorneyName}</Link>
            <div className="info-content">
              <span className={`attorney-practice ${arabicLang ? "ge-two-light" : "para-regular-font"}`} style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{attorneyInfo1}</span>
              <span className={`attorney-personal ${arabicLang ? "ge-two-light" : "para-regular-font"}`} style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{attorneyInfo2}</span>
            </div>
        </div>
      </div>
    </div>
  );
}