import "./formStyle.css";
import { useRef, useState, useEffect } from "react";
import TopNavbar from "../../components/navbar/TopNavbar";
import Third_party from "../../components/button/Third_party";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

export default function SignupPage() {

    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language === "ar");
    const navigate = useNavigate();
    const auth = getAuth();

    const emailRef = useRef();
    const passwordRef = useRef();
    const [errorMsg, setErrorMsg] = useState(false);

    useEffect(() => {
        setErrorMsg(false);
    }, [i18n.language])

    async function handleSubmit(e) {
        e.preventDefault();
        createUserWithEmailAndPassword(auth, emailRef.current.value, passwordRef.current.value)
        .then(() => { 
            navigate("/");
        })
        .catch((error) => {
            const errorCode = error.code;
            console.log(errorCode);
            switch(error.code) {
                case "auth/invalid-email":
                    setErrorMsg(t("alertMsg.signup_page.invalid_email"));
                    break;
                case "auth/weak-password":
                    setErrorMsg(t("alertMsg.signup_page.password6"));
                    break;
                case "auth/email-already-in-use":
                    setErrorMsg(t("alertMsg.signup_page.email_in_use"));
                    break;
                default:
                    setErrorMsg(t("alertMsg.third_party.wrong"));
            }
        });
    }

    return (
        <div className="auth-page">
            <div className="header-padding">
                <div className="dark-header">
                    <TopNavbar mode="navbar-dark-mode"/>

                    <div className="auth-form-content">
                        <div className="auth-form">
                            <div className="content">
                                <div>
                                    <h1 className={`form-title ${arabicLang ? "dubai-bold" : "main-title"}`} style={arabicLang ? {maxWidth: "90%"} : {}}>{t("page.auth.title.signup")}</h1>
                                </div>
                                <Third_party setErrorMsg={setErrorMsg} />

                                <div>
                                    <p className={`form-desc ${arabicLang ? "ge-two-light" : "main-title"}`}>{t("page.auth.desc.signup")}</p>
                                </div>
                                {errorMsg && <span className={`errorMsg ${i18n.language == "ar" ? "ge-two-light" : "para-regular-font"}`}>{errorMsg}</span>}
                                <form className="group-form-content" onSubmit={(e) => handleSubmit(e)}>
                                    <input ref={emailRef} name="email" type="email" className="auth-input" placeholder={t("input.placeholder.email")} style={arabicLang ? {fontFamily: "ge-two-bold"} : {fontFamily: "gliroy-semibold"}} required/>
                                    <input ref={passwordRef} name="password" type="password" className="auth-input" placeholder={t("input.placeholder.password")} style={arabicLang ? {fontFamily: "ge-two-bold"} : {fontFamily: "gliroy-semibold"}} required/>
                                    <button type="submit" className="submit-btn" style={arabicLang ? {fontFamily: "dubai-bold"} : {fontFamily: "gliroy-semibold"}}>{t("button.register")}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}