import "./detailsStyle.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// components
import TopNavbar from "../../components/navbar/TopNavbar";
import Footer from "../../components/footer/Footer";
import ScrollbarDark from "../../components/scrollbar/ScrollbarDark.tsx";

// images
import favMsg from "../../img/svg/fav_msg_icon.svg";
import callIcon from "../../img/svg/call_icon.svg";
import locationDark from "../../img/svg/location_dark.svg";
import fbIcon from "../../img/svg/fb_icon.svg";
import twIcon from "../../img/svg/tw_icon.svg";
import instaIcon from "../../img/svg/insta_icon.svg";
import userTag from "../../img/svg/user_tag.svg";
import education from "../../img/svg/education.svg";
import award from "../../img/svg/award.svg";
import userTagActive from "../../img/svg/user_tag_active.svg";
import educationActive from "../../img/svg/education_active.svg";
import awardActive from "../../img/svg/award_active.svg";
import attorneyImgFull from "../../img/attorney_img_full.png";

export default function Details() {
    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language === "ar");
    const [active, setActive] = useState(1);
  return (
    <ScrollbarDark>
      <div className="details-page">

        <div className="header-padding">
            <div className="header dark-header" style={arabicLang ? {background: "linear-gradient(220.5deg, #37363E 0.63%, #424149 28.44%, #2B2B31 56.78%, #111114 81.51%)"} : {}}>
              <TopNavbar mode="navbar-dark-mode"/>
              <div className="container">
                  <div className="title-content">
                    <h1 className={`header-title color-dark-mode ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("page.details.section.title.attorney_name")}</h1>
                    <p className={`section-desc color-dark-moded-desc ${arabicLang ? "ge-two-light" : "main-title"}`}>{t("page.details.section.desc.main")}</p>
                  </div>
              </div>
            </div>
        </div>

        <main className="details-page-content">
            <section className="attorney-details-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="attorney-img">
                                <img className="mb-4 mb-lg-0" src={attorneyImgFull} alt="attorney" style={arabicLang ? {transform: "scale(-1, 1)"} : {}}/>
                            </div>
                        </div>
                        <div className={`col-lg-5 ${arabicLang ? "offset-lg-1-rtl" : "offset-lg-1"}`}>
                            <div className="attorney-details">
                                <div><h5 className={`attorney-name ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("page.details.section.title.attorney_name")}</h5></div>
                                <div className="about-attorney">
                                    <h6 className={`attorney-about-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("page.details.about.title.about")}</h6>
                                    <p className={`attorney-about-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.about.desc.about")}</p>
                                </div>
                                <div className="d-none d-lg-block">
                                    <div className="attorney-contact">
                                        <h5 className={`attorney-contact-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("page.details.about.title.contact")}</h5>
                                        <ul className="attorney-contact-list">
                                            <li><img src={favMsg} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "12px"}: {}}/><span className={arabicLang ? "ge-two-light" : "para-regular-font"}>info@daniel kim.com</span></li>
                                            <li><img src={callIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "12px"}: {}}/><span className={arabicLang ? "ge-two-light" : "para-regular-font"}>(123) 567 8901</span></li>
                                            <li><img src={locationDark} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "12px"}: {}}/><span className={arabicLang ? "ge-two-light" : "para-regular-font"}>Jeddah Bab Makkah Dist.</span></li>
                                        </ul>
                                    </div>
                                    <div className="attorney-social">
                                        <h3 className={`menu-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("page.details.about.title.social")}</h3>
                                        <ul className={`social-media ${i18n.language == "ar" ? "pushRight" : ""}`}>
                                            <li><a href="#"><img src={fbIcon} alt="icon" /></a></li>
                                            <li><a href="#"><img src={twIcon} alt="icon" /></a></li>
                                            <li><a href="#"><img src={instaIcon} alt="icon" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="attorney-desc-content">
               <div className="container">
                    <div className="desc-header">
                        <ul className="list-inline list-title">
                            <li className={`list-inline-item ${arabicLang ? "li-margin-left" : ""}`} onClick={() => setActive(1)} >
                                <div className={`d-flex list-content ${active === 1 ? "active" : ""}`}>
                                    {active !== 1 && <img src={userTag} className={arabicLang ? "img-margin-left" : ""} alt="icon" />}
                                    {active === 1 && <img src={userTagActive} className={arabicLang ? "img-margin-left" : ""} alt="icon" />}
                                    <span className={arabicLang ? "dubai-bold" : "main-title"}>{t("page.details.description_title.user_tag")}</span>
                                </div>
                            </li>
                            <li className={`list-inline-item ${arabicLang ? "li-margin-left" : ""}`} onClick={() => setActive(2)}>
                                <div className={`d-flex list-content ${active === 2 ? "active" : ""}`}>
                                    {active !== 2 && <img src={education} className={arabicLang ? "img-margin-left" : ""} alt="icon" />}
                                    {active === 2 && <img src={educationActive} className={arabicLang ? "img-margin-left" : ""} alt="icon" />}
                                    <span className={arabicLang ? "dubai-bold" : "main-title"}>{t("page.details.description_title.education")}</span>
                                </div>
                            </li>
                            <li className={`list-inline-item ${arabicLang ? "li-margin-left" : ""}`} onClick={() => setActive(3)}>
                                <div className={`d-flex list-content ${active === 3 ? "active" : ""}`}>
                                    {active !== 3 && <img src={award} className={arabicLang ? "img-margin-left" : ""} alt="icon" />}
                                    {active === 3 && <img src={awardActive} className={arabicLang ? "img-margin-left" : ""} alt="icon" />}
                                    <span className={arabicLang ? "dubai-bold" : "main-title"}>{t("page.details.description_title.award")}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="desc-body">
                        {active === 1 && <div className="user-tag-desc">
                            <p className={`attorney-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.description.user_tag.1")}</p>
                            <p className={`attorney-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.description.user_tag.2")}</p>
                            <p className={`attorney-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.description.user_tag.3")}</p>
                        </div>}

                        {active === 2 && <div className="user-tag-desc">
                            <p className={`attorney-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.description.education.1")}</p>
                            <p className={`attorney-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.description.education.2")}</p>
                            <p className={`attorney-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.description.education.3")}</p>
                        </div>}

                        {active === 3 && <div className="user-tag-desc">
                            <p className={`attorney-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.description.award.1")}</p>
                            <p className={`attorney-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.description.award.2")}</p>
                            <p className={`attorney-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.description.award.3")}</p>
                        </div>}
                    </div>
                    <div className="d-block d-lg-none">
                        <div className="attorney-contact">
                            <h5 className={`attorney-contact-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("page.details.about.title.contact")}</h5>
                            <ul className="attorney-contact-list">
                                <li><img src={favMsg} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "12px"}: {}}/><span className={arabicLang ? "ge-two-light" : "para-regular-font"}>info@daniel kim.com</span></li>
                                <li><img src={callIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "12px"}: {}}/><span className={arabicLang ? "ge-two-light" : "para-regular-font"}>(123) 567 8901</span></li>
                                <li><img src={locationDark} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "12px"}: {}}/><span className={arabicLang ? "ge-two-light" : "para-regular-font"}>Jeddah Bab Makkah Dist.</span></li>
                            </ul>
                        </div>
                        <div className="attorney-social">
                            <h3 className={`menu-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("page.details.about.title.social")}</h3>
                            <ul className={`social-media ${i18n.language == "ar" ? "pushRight" : ""}`}>
                                <li><a href="#"><img src={fbIcon} alt="icon" /></a></li>
                                <li><a href="#"><img src={twIcon} alt="icon" /></a></li>
                                <li><a href="#"><img src={instaIcon} alt="icon" /></a></li>
                            </ul>
                        </div>
                    </div>
               </div>
            </section>

            <section className="about-law-firm">
               <div className="container">
                    <h4 className={`about-law-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("page.details.about_law.title")}</h4>
                    <div>
                        <p className={`about-law-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.about_law.desc.1")}</p>
                        <p className={`about-law-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.about_law.desc.2")}</p>
                        <p className={`about-law-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.details.about_law.desc.3")}</p>
                    </div>
               </div>
            </section>
        </main>

        <Footer />
      </div>
    </ScrollbarDark>
  );
}

