import {Link} from "react-router-dom";
import "./search-style.css";
// images
import searchIconDark from "../../img/svg/search_icon_dark.svg";
import mapIconDark from "../../img/svg/map_dark.svg";
import timerDark from "../../img/svg/timer_dark.svg";
import awardDark from "../../img/svg/award_dark.svg";
import search_now_arrow from "../../img/svg/search_now_arrow.svg";
// components
import { useTranslation } from "react-i18next";

export default function SearchBarDark() {
  const [t, i18n] = useTranslation();
  const arabicLang = (i18n.language == "ar");

  return (
  <div className='search-bar-dark'>
    <div className="container">
      <div className="search-bar-box search-bar-dark-box">
        <div className="d-none d-md-flex search-btn-content">
          <Link to="/search" className="search-now-btn" style={arabicLang ? {borderImage: "linear-gradient(200deg, #FFFFFF -249.63%, rgba(255, 255, 255, 0) 124.62%)", borderImageSlice: "1"} : {}}>
            <span>{t("button.search_now")}</span>
            <span><img src={search_now_arrow} alt="icon" style={(i18n.language == "ar") ? {transform: "rotate(180deg)", marginRight: "8px", marginLeft: "0"} : {}}/></span>
          </Link>
        </div>
        <div className="search-form" style={arabicLang ? {borderImage: "linear-gradient(207.19deg, #FFFFFF -65.42%, rgba(255, 255, 255, 0) 104.32%)", borderImageSlice: "1"} : {}}>
          <div className="container-fluid g-0">
            <div className="row g-0" style={arabicLang ? {textAlign: "right"} : {}}>
              <div className="col-lg-2">
                <div className={`search-icon d-none d-lg-inline-flex ${arabicLang ? "border-l" : ""}`}>
                  <button className="search-btn-primary">
                    <span><img src={searchIconDark} alt="search icon" style={arabicLang ? {marginRight: "0", marginLeft: "6px"} : {}}/></span>
                    <span>{t("button.search")}</span>
                  </button>
                </div>
              </div>
              <div className="col-lg-10">
                <div className={`row inputs g-3 g-xl-5 ${arabicLang ? "ms-auto" : ""}`}>
                  <div className="col-lg-4">
                    <div className="group-input">
                      <img src={mapIconDark} alt="icon" style={arabicLang ? {right: "14px", left: "0"} : {}}/>
                      <input type="text" className={`form-control ${arabicLang ? "paddingRight-with-font border-right" : ""}`} placeholder={t("input.placeholder.location")}/>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="group-input">
                      <img src={timerDark} alt="icon" style={arabicLang ? {right: "14px", left: "0"} : {}}/>
                      <input type="text" className={`form-control ${arabicLang ? "paddingRight-with-font border-right" : ""}`} placeholder={t("input.placeholder.time")}/>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="group-input">
                      <img src={awardDark} alt="icon" style={arabicLang ? {right: "14px", left: "0"} : {}}/>
                      <input type="text" className={`form-control ${arabicLang ? "paddingRight-with-font border-right" : ""}`} placeholder={t("input.placeholder.speciality")}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex d-md-none search-btn-content">
          <Link to="/search" className="search-now-btn" style={arabicLang ? {borderImage: "linear-gradient(200deg, #FFFFFF -249.63%, rgba(255, 255, 255, 0) 124.62%)", borderImageSlice: "1"} : {}}>
            <span>{t("button.search_now")}</span>
            <span><img src={search_now_arrow} alt="icon" style={(i18n.language == "ar") ? {transform: "rotate(180deg)", marginRight: "8px", marginLeft: "0"} : {}}/></span>
          </Link>
        </div>
      </div>
    </div>
  </div>
  )
}
