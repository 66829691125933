import {useEffect, useState} from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { userContext } from "./context/userContext";

// Components
import Home from "./pages/homePage/Home";
import SearchPage from "./pages/searchPage/SearchPage";
import Details from "./pages/detailsPage/Details";
import LoginPage from "./pages/authPage/LoginPage";
import SignupPage from "./pages/authPage/SignupPage";
import CodePage from "./pages/authPage/CodePage";

function App() {

  const {i18n} = useTranslation();
  const lang = localStorage.getItem('i18nextLng') || 'ar';
  const auth = getAuth();
  const [user, setUser] = useState(null);
  // create random code for 6 digit code
  const [randomCode, setRandomCode] = useState(0);

  useEffect(() => {
    onAuthStateChanged(auth, authUser => {
      if (authUser) {
        setUser(authUser);
        console.log("log in");
      } else {
        setUser(null);
        console.log("logout");
      }
    })
  }, []);

  useEffect(()=>{
    if(lang === 'ar'){
        document.querySelector('title').textContent= 'محامي';
        document.querySelector('body').style.fontFamily = "dubai-bold";
        document.querySelector('body').style.direction = i18n.dir();
      }
      else{
        document.querySelector('title').textContent= 'Davis';
        document.querySelector('body').style.fontFamily = "gliroy-semibold";
        document.querySelector('body').style.direction = i18n.dir();
      }
    },[lang])

  return (
    <BrowserRouter>
      <userContext.Provider value={user}>
        <div className="App">
            <div className="global-background">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="search" element={<SearchPage />} />
                  <Route path="details" element={<Details />} />
                  {/* <Route path="details/:id" element={<Details />} /> */}
                  <Route path="login" element={user === null ? <LoginPage setRandomCode={setRandomCode} randomCode={randomCode} /> : <Navigate to="/" />} />
                  <Route path="signup" element={user === null ? <SignupPage /> : <Navigate to = "/" />} />
                  <Route path="code" element={user === null ? <CodePage randomCode={randomCode} /> : <Navigate to="/" />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
        </div>
      </userContext.Provider>
    </BrowserRouter>
  );
}
export default App;