import "./home_style.css";
// images
import call_icon from "../../img/svg/call_icon.svg";
import medal_star from "../../img/svg/medal_star.svg";
import aboutImg1 from "../../img/about_img1.png";
import aboutImg2 from "../../img/about_img2.png";
import legalImg1 from "../../img/legal_img1.png";
import legalImg2 from "../../img/legal_img2.png";
import legalImg3 from "../../img/legal_img3.png";
// components
import TopNavbar from "../../components/navbar/TopNavbar";
import Footer from "../../components/footer/Footer";
import SearchBarLight from "../../components/searchBar/SearchBarLight";
import SectionTitle from "../../components/section/SectionTitle";
import PracticeCarousel from "../../components/carousel/PracticeCarousel";
import LegalAccordion from "../../components/accordion/LegalAccordion";
import TestimonialCarousel from "../../components/carousel/TestimonialCarousel";
import AttorneysCarousel from "../../components/carousel/AttorneysCarousel";
import { useTranslation } from "react-i18next";
import Scrollbar from "../../components/scrollbar/Scrollbar.tsx";

export default function Home() {
    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language === "ar");

  return(
      <Scrollbar>
        <div className='home-page'>
            <TopNavbar mode="navbar-light-mode"/>
            <div className="header">
                <div className='container'>
                    <div className="row g-0">
                        <div className="col-lg-6">
                            <div className="header-desc">
                                <span className={`header-hint ${arabicLang ? "ge-two-bold" : "hint-bold-font"}`}>{t("page.home.section.hint.main")}</span>
                                <h1 className={`header-title ${arabicLang ? "aHeader-title" : "main-title"}`}>{t("page.home.section.title.main")}</h1>
                                <p className="header-subtitle para-regular-font" style={arabicLang ? {fontFamily: "ge-two-light", width: "80%"} : {}}>{t("page.home.section.desc.main")}</p>
                                <div className="btn-content">
                                    <button className="btn-primary-color" style={arabicLang ? {padding: "16px 50px"} : {}}>{t("button.schedule")}</button>
                                    <button className="btn-call" style={arabicLang ? {marginLeft: "0", marginRight: "45px"} : {}}>
                                        {i18n.language === "en" && <img src={call_icon} alt="call" />}
                                        <div className="info" style={arabicLang ? {fontFamily: "ge-two-light"} : {}}>
                                            <span style={arabicLang ? {textAlign: "center"} : {}}>{t("button.or_call")}</span>
                                            <span style={arabicLang ? {direction: "ltr"} : {}}>0123 567 8901</span>
                                        </div>
                                        {i18n.language === "ar" && <img src={call_icon} alt="call" />}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 d-none d-lg-flex header-img-content" style={arabicLang ? {transform: "scale(-1, 1)", justifyContent: "flex-start"} : {}}>
                            <div className="header-img background-primary-dark">
                                <div className="overlay">
                                    <div className="experience_box" style={arabicLang ? {transform: "scale(-1, 1)", padding: "42px 70px"} : {}}>
                                        <div className="inner-box">
                                            <div>
                                                <img src={medal_star} alt="medal" />
                                            </div>
                                            <div className="number main-title" style={arabicLang ? {direction: "ltr"} : {}}>10+</div>
                                            <h5 className={`exper-para ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.home.section.desc.year")}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main">
                <section>
                    <SearchBarLight />
                </section>

                <section id="practice-areas" className="practice">
                    <div className="container">
                        <div className="row" style={arabicLang ? {alignItems: "center"} : {}}>
                            <div className="col-lg-6">
                            <SectionTitle
                                hint={t("page.home.section.hint.practice")}
                                title={t("page.home.section.title.practice")} />
                            </div>
                            <div className="col-lg-6 section-desc-content">
                                <p className={`section-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("page.home.section.desc.practice")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="practice-carousel-content">
                        <PracticeCarousel />
                    </div>
                </section>

                <section id="about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <SectionTitle hint={t("page.home.section.hint.about_us")}
                                    title={t("page.home.section.title.about_us")}/>
                            </div>
                        </div>
                        <div className="about-para">
                            <div className="row">
                            <div className={`col-lg-5 section-desc-content ${arabicLang ? "offset-lg-1-rtl" : "offset-lg-1"}`}>
                                <p className={`section-desc ${arabicLang ? "para-width-font" : "para-regular-font"}`}>{t("page.home.section.desc.about_us1")}</p>
                            </div>
                            <div className={`col-lg-5 section-desc-content ${arabicLang ? "offset-lg-1-rtl" : "offset-lg-1"}`}>
                                <p className={`section-desc ${arabicLang ? "para-width-font" : "para-regular-font"}`}>{t("page.home.section.desc.about_us2")}</p>
                            </div>
                            </div>
                        </div>
                        <div className="about-img-content">
                            <img className={`about-img1 ${arabicLang ? "pushLeft" : ""}`} src={aboutImg1} alt="about"/>
                            <div className="row">
                                <div className="d-none d-lg-block col-md-5">
                                    <img className="about-img2" src={aboutImg2} alt="about" />
                                </div>
                                <div className={`col-lg-5 ${arabicLang ? "offset-lg-2-rtl offset-xl-1-rtl" : "offset-lg-2 offset-xl-1"}`}>
                                    <p className={`section-desc ${arabicLang ? "para-width-font" : "para-regular-font"}`}>{t("page.home.section.desc.about_us3")}</p>
                                    <div className="exper-year" style={arabicLang ? {flexDirection: "row-reverse", justifyContent: "flex-end"} : {}}>
                                        <h4 className="number main-title" style={arabicLang ? {direction: "ltr"} : {}}>10+</h4>
                                        <p className={`year ${arabicLang ? "ge-two-light" : "para-regular-font"}`} style={arabicLang ? {marginLeft: "0", textAlign: "center", width: "30%"} : {}}>{t("page.home.section.desc.about_us_year")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="legal">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <SectionTitle hint={t("page.home.section.hint.legal")} 
                                    title={t("page.home.section.title.legal")} />
                                <LegalAccordion />
                            </div>
                            <div className={`d-none d-lg-block col-lg-6 ${arabicLang ? "offset-lg-1-rtl" : "offset-lg-1"}`}>
                                <div className="legal-img-content">
                                    <img className="legal-img1" src={legalImg1} alt="legal" />
                                    <img className="legal-img2" src={legalImg2} alt="legal" />
                                    <img className="legal-img3" src={legalImg3} alt="legal" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="testimonials">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <SectionTitle hint={t("page.home.section.hint.testimonial")}
                                    title={t("page.home.section.title.testimonial")} />
                            </div>
                            <div className={`col-lg-5 ${arabicLang ? "offset-lg-2-rtl" : "offset-lg-2"}`}>
                                <div className="testmonial-carousel-content">
                                    <TestimonialCarousel />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="attorneys">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <SectionTitle hint={t("page.home.section.hint.attorney")}
                                    title={t("page.home.section.title.attorney")} />
                            </div>
                            <div className="col-lg-7 section-desc-content">
                                <p className={`section-desc ${arabicLang ? "para-width-font2" : "para-regular-font"}`}>{t("page.home.section.desc.attorney")} </p>
                                <div className="btn-content" style={arabicLang ? {marginLeft: "0", marginRight: "auto"} : {}}>
                                    <button className="btn-primary-dark">{t("button.see_all")}</button>
                                </div>
                            </div>
                        </div>
                        <div className="attorneys-carousel-content">
                            <AttorneysCarousel />
                        </div>
                    </div>
                </section>

                <section id="collaboration">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <SectionTitle hint={t("page.home.section.hint.collaboration")}
                                    title={t("page.home.section.title.collaboration")} />
                            </div>
                            <div className="col-lg-6">
                                <p className={`section-desc ${arabicLang ? "para-width-font2" : "para-regular-font"}`}>{t("page.home.section.desc.collaboration")}</p>
                                <button className="btn-primary-dark" style={arabicLang ? {padding: "15px 87px"} : {}}>{t("button.sendMsg")}</button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    </Scrollbar>
  );
}
