// import { initializeApp } from "firebase/app";
// const firebaseConfig = {
//     apiKey: "AIzaSyCW7JVLgtxo8HXQXnbAZmxk3VkcfTAvWEw",
//     authDomain: "davis-auth.firebaseapp.com",
//     projectId: "davis-auth",
//     storageBucket: "davis-auth.appspot.com",
//     messagingSenderId: "554710564105",
//     appId: "1:554710564105:web:81d1300cf9faa8db3e67d8"
// };
// const app = initializeApp(firebaseConfig);

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBgoF8fymxA2EOm-1sqp3KVftwlWWQiTXY",
    authDomain: "muhammiplatform.firebaseapp.com",
    projectId: "muhammiplatform",
    storageBucket: "muhammiplatform.appspot.com",
    messagingSenderId: "992676022747",
    appId: "1:992676022747:web:23c97ee1cb79f2d2843ffe",
    measurementId: "G-5B7H9K1YXY"
  };

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth, db };