import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import "./components/firebase.config";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./css/fonts.css";
import "./css/global_aStyle.css";
import "./css/global_style.css";
import "./i18n";
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<p>Loading...</p>}>
    <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);