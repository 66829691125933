import "./carouselStyle.css";
import {useRef, useEffect, useState} from "react";
import { Carousel } from "react-bootstrap";
import clientImg from "../../img/client_image.png";
import carouselArrowLeft from "../../img/svg/carousel_arrow_left.svg";
import carouselArrowRight from "../../img/svg/carousel_arrow_right.svg";
import { useTranslation } from "react-i18next";

export default function TestimonialCarousel() {

    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language == "ar");

    const [index, setIndex] = useState(1);
    const [total, setTotal] = useState();
    const carouselRef = useRef();

    useEffect(() => {
        // change button direction
        if (arabicLang) {
            carouselRef.current.element.childNodes[1].classList.add("rightSide");
        } else {  
            carouselRef.current.element.childNodes[1].classList.remove("rightSide");
        }
        getTotal();
    }, [i18n.language]);

    function getTotal() {
        setTotal(carouselRef.current.element.childNodes[0].childNodes.length);
    }

    function handleSelect(selectedIndex) {
        setIndex(selectedIndex + 1);
    }

    return (
        <div className="testimonial-carousel">
            <Carousel onSelect={(e) => handleSelect(e)} ref={carouselRef} id="testimonial-carousel" indicators={false} prevIcon={<img src={carouselArrowLeft} alt="arrow" />} nextIcon={<img src={carouselArrowRight} alt="arrow" />}>
                {[1,2,3,4,5,6].map(index => (
                    <Carousel.Item key={index}>
                        <div className="client">
                            <img src={clientImg} alt="client" style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}/>
                            <div className="client-info">
                                <span className={`client-name ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("carousel.testimonial.client_name")}</span>
                                <span className={`client-job ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("carousel.testimonial.client_job")}</span>
                            </div>
                        </div>
                        <p className={`client-comment ${arabicLang ? "para-width-font" : "para-regular-font"}`}>{t("carousel.testimonial.client_comment")}</p>
                    </Carousel.Item>
                ))}
            </Carousel>
            
            <div className="slid-count" style={arabicLang ? {marginLeft: "0", marginRight: "40px", fontFamily: "dubai-bold"} : {fontFamily: "rox-cf"}}>
                <span>{index} </span>
                <span>{t("carousel.testimonial.of")}</span>
                <span>{total}</span>
            </div>
      </div>
  );
}