import {useState, useEffect, useRef} from "react";
import { Carousel } from "react-bootstrap";

import AttorneyCard from "../card/AttorneyCard";

// images
import { useTranslation } from "react-i18next";
import attorneyImg1 from "../../img/attorney_img1.png";
import attorneyImg2 from "../../img/attorney_img2.png";
import attorneyImg3 from "../../img/attorney_img3.png";

export default function MeetCarousel() {
    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language == "ar");

    const [width, setWidth] = useState(0);
    const [total, setTotal] = useState();
    const [index, setIndex] = useState(0);
    const carouselRef = useRef();
    const indicatorBar = useRef();

    useEffect(() => {
        setTotal(carouselRef.current.element.childNodes[0].childNodes.length);
    }, [])

    function handlePrev() {
        carouselRef.current.prev();
    }
    function handleNext() {
        carouselRef.current.next();
    }
    function handleSelect(selectedIndex) {
        setIndex(selectedIndex + 1);
        setWidth((selectedIndex + 1) * (100 / total) + "%");
    }

  return (
      <div className="attorneys-carousel">
        <Carousel onSelect={(e) => handleSelect(e)} ref={carouselRef} indicators={false} controls={false} id="attorneys-carousel">
            {[1,2,3,4,5,6,7,8].map(index => (
                <Carousel.Item key={index}>
                    <div className="row g-5">
                        <div className="col-lg-4">
                            <AttorneyCard
                                imgSrc={attorneyImg1}
                                attorneyName={t(`carousel.attorneys.attorney1.name`)}
                                attorneyInfo1={t(`carousel.attorneys.attorney1.practice_title`) + t(`carousel.attorneys.attorney1.practice`)}
                                attorneyInfo2={t(`carousel.attorneys.attorney1.personal`)}
                            />
                        </div>
                        <div className="col-lg-4">
                            <AttorneyCard
                                imgSrc={attorneyImg2}
                                attorneyName={t(`carousel.attorneys.attorney2.name`)}
                                attorneyInfo1={t(`carousel.attorneys.attorney2.practice_title`) + t(`carousel.attorneys.attorney2.practice`)}
                                attorneyInfo2={t(`carousel.attorneys.attorney2.personal`)}
                            />
                        </div>
                        <div className="col-lg-4">
                            <AttorneyCard
                                imgSrc={attorneyImg3}
                                attorneyName={t(`carousel.attorneys.attorney3.name`)}
                                attorneyInfo1={t(`carousel.attorneys.attorney3.practice_title`) + t(`carousel.attorneys.attorney3.practice`)}
                                attorneyInfo2={t(`carousel.attorneys.attorney3.personal`)}
                            />
                        </div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>

        <div className="content">
            <div className="indicator-content">
                <span onClick={() => handlePrev()} className={`handle ${arabicLang ? "dubai-bold" : "main-title"}`}>{String(index).padStart(2, "0")}</span>
                <div className="indicator-bar-content">
                    <div ref={indicatorBar} className="indicator-bar" style={{width: width}}></div>
                </div>
                <span onClick={() => handleNext()} className={`handle ${arabicLang ? "dubai-bold" : "main-title"}`}>{String(total).padStart(2, "0")}</span>
            </div>
        </div>
      </div>
  );
}