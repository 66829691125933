import "./footerStyle.css";
import fbIcon from "../../img/svg/fb_icon.svg";
import twIcon from "../../img/svg/tw_icon.svg";
import instaIcon from "../../img/svg/insta_icon.svg";
import {useTranslation} from "react-i18next";

export default function Footer() {
    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language == "ar");

    return (
        <div className="footer">
            <div className="container">
                <div className="footer-menu">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-6 menu">
                                    <h3 className={`menu-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("footer.footer-menu.product.title")}</h3>
                                    <ul className={arabicLang ? "list-font" : ""}>
                                        <li><a href="#" >{t("footer.footer-menu.product.0")}</a></li>
                                        <li><a href="#" >{t("footer.footer-menu.product.1")}</a></li>
                                        <li><a href="#" >{t("footer.footer-menu.product.2")}</a></li>
                                        <li><a href="#" >{t("footer.footer-menu.product.3")}</a></li>
                                        <li><a href="#" >{t("footer.footer-menu.product.4")}</a></li>
                                    </ul>
                                </div>
                                <div className="col-6 menu">
                                    <h3 className={`menu-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("footer.footer-menu.services.title")}</h3>
                                    <ul className={arabicLang ? "list-font" : ""}>
                                        <li><a href="#" >{t("footer.footer-menu.services.0")}</a></li>
                                        <li><a href="#" >{t("footer.footer-menu.services.1")}</a></li>
                                        <li><a href="#" >{t("footer.footer-menu.services.2")}</a></li>
                                        <li><a href="#" >{t("footer.footer-menu.services.3")}</a></li>
                                        <li><a href="#" >{t("footer.footer-menu.services.4")}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-6 menu">
                                    <h3 className={`menu-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("footer.footer-menu.need_help.title")}</h3>
                                    <p className="phone-number" style={arabicLang ? {direction: "ltr", fontFamily: "ge-two-light", textAlign: "right"} : {}}>{t("footer.footer-menu.need_help.0")}</p>
                                    <h3 className={`menu-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("footer.footer-menu.need_support.title")}</h3>
                                    <p className="email">{t("footer.footer-menu.need_support.0")}</p>
                                </div>
                                <div className="col-6 menu">
                                    <h3 className={`menu-title socail-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("footer.footer-menu.social_media.title")}</h3>
                                    <ul className={`social-media ${i18n.language == "ar" ? "pushRight" : ""}`}>
                                        <li><a href="#"><img src={fbIcon} alt="icon" /></a></li>
                                        <li><a href="#"><img src={twIcon} alt="icon" /></a></li>
                                        <li><a href="#"><img src={instaIcon} alt="icon" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-right">
                    <h5 className="copy" style={arabicLang ? {fontFamily: "ge-two-bold"} : {}}>{t("footer.copy_right")}</h5>
                </div>
            </div>
        </div>
    );
}