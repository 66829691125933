import { useContext } from 'react';
import { userContext } from '../../context/userContext';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getAuth, signOut } from 'firebase/auth';
import { HashLink } from 'react-router-hash-link';
import SignUp from '../button/SignUp';

import "./navbar_style.css";
// images
import logo from "../../img/svg/logo.svg";
import aLogo from "../../img/svg/aLogo.svg";
import logoDarkMode from "../../img/svg/logo_dark_mode.svg";
import alogoDarkMode from "../../img/svg/alogo_dark_mode.svg";

export default function TopNavbar({mode}) {

    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language == "ar");
    const navigate = useNavigate();
    const auth = getAuth();
    const user = useContext(userContext);
    
    async function handleLogout() {
        signOut(auth);
        navigate("/");
    }

  return (
      <Navbar className={`navbar ${mode}`} expand="lg">
          {console.log(arabicLang)}
        <Container>
            {!arabicLang && <Navbar.Brand><Link to="/">{mode === "navbar-light-mode" ? <img src={logo} alt="logo" /> : <img src={logoDarkMode} alt="logo" />}</Link></Navbar.Brand>}
            {arabicLang && <Navbar.Brand to="/" style={(arabicLang) ? {marginLeft: "1rem", marginRight: "0"} : {}}><Link to="/">{mode === "navbar-light-mode" ? <img src={aLogo} alt="logo" /> : <img src={alogoDarkMode} alt="logo" />}</Link></Navbar.Brand>}
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={`m-auto ${arabicLang == "ar" && "mLeft"}`}>
                <Link className={`nav-link ${arabicLang ? "mLeft" : ""}`} to="/">{t("navbarLink.0")}</Link>
                <HashLink className={`nav-link ${arabicLang ? "mLeft" : ""}`} smooth to="/#practice-areas" >{t("navbarLink.1")}</HashLink>
                <HashLink className={`nav-link ${arabicLang ? "mLeft" : ""}`} smooth to="/#members">{t("navbarLink.2")}</HashLink>
                <HashLink className={`nav-link ${arabicLang ? "mLeft" : ""}`} smooth to="/#about-us">{t("navbarLink.3")}</HashLink>
                <HashLink className={`nav-link ${arabicLang ? "mLeft" : ""}`} smooth to="/#contact">{t("navbarLink.4")}</HashLink>
                {!arabicLang && <span className={`translator ${arabicLang ? "mLeft" : "dubai-bold"}`} onClick={() => i18n.changeLanguage("ar")}>العربية</span>}
                {arabicLang && <span className={`translator ${arabicLang ? "mLeft" : "hint-bold-font"}`} onClick={() => i18n.changeLanguage("en")}>en</span>}
            </Nav>
            <div className="btn-content-nav">
                {!user && <button className={`login-btn ${arabicLang ? "mLeft30" : ""}`} style={(mode === "navbar-light-mode") ? {color: "#6E757C"} : {color: "#FFFFFF"}}><Link to="/login">{t("button.login")}</Link></button>}
                {!user && <SignUp mode={mode} t = {t} arabicLang={arabicLang}/>}
                {user && <span onClick={() => {handleLogout()}} className={`logout ${arabicLang ? "mLeft30" : ""}`} style={(mode === "navbar-light-mode") ? {color: "#6E757C"} : {color: "#FFFFFF"}}>{t("button.logout")}</span>}
            </div>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        )
}
