import {useRef, useEffect} from "react";
import { Accordion } from "react-bootstrap";
import "./accordionStyle.css";
import { useTranslation } from "react-i18next";

export default function LegalAccordion() {

  const [t, i18n] = useTranslation();
  const arabicLang = (i18n.language === "ar");

  // this code will push all accordion + and - buttons into left by adding ID to each element when we use arabic version.
  const accordionRef = useRef()
  useEffect(() => {
    if (arabicLang) {
      for (let i = 0; i < accordionRef.current.childNodes.length; i++) {
        accordionRef.current.childNodes[i].childNodes[0].childNodes[0].setAttribute("id", "mRight-auto");
      } 
    } else {
      for (let i = 0; i < accordionRef.current.childNodes.length; i++) {
        accordionRef.current.childNodes[i].childNodes[0].childNodes[0].setAttribute("id", "");
      }
    }
  }, [i18n.language])


  return (
    <div className="legal-accordion">
      <Accordion ref={accordionRef} className="accordion-header" defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header><h4 className={`accordion-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("accordion.legal.box1.title")}</h4></Accordion.Header>
        <Accordion.Body>
            <p className={`section-desc ${arabicLang ? "para-width-font" : "para-regular-font"}`}>{t("accordion.legal.box1.desc")}</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header><h4 className={`accordion-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("accordion.legal.box2.title")}</h4></Accordion.Header>
        <Accordion.Body>
            <p className={`section-desc ${arabicLang ? "para-width-font" : "para-regular-font"}`}>{t("accordion.legal.box2.desc")}</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="2">
        <Accordion.Header><h4 className={`accordion-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("accordion.legal.box3.title")}</h4></Accordion.Header>
        <Accordion.Body>
            <p className={`section-desc ${arabicLang ? "para-width-font" : "para-regular-font"}`}>{t("accordion.legal.box3.desc")}</p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </div>
  )
}
