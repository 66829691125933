//images
import googleIcon from "../../img/svg/google_icon.svg";
import appleIcon from "../../img/svg/apple_icon.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

export default function Third_party({setErrorMsg}) {
    
    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language === "ar");
    const auth = getAuth();
    const navigate = useNavigate();

    function handleGoogleProvider() {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
        .then(cred => {
            navigate("/");
        }).catch((error) => {
            setErrorMsg(t("alertMsg.third_party.wrong"))
        })
    }

    function handleAppleProvider() {
        const provider = new OAuthProvider('apple.com');
        signInWithPopup(auth, provider)
        .then((cred) => {
            console.log(cred.user);
        }).catch(error => {
            setErrorMsg(t("alertMsg.third_party.wrong"))
        })
    }

  return (
    <div className="third-party-btn-content" dir={arabicLang ? "ltr" : ""}>
        <div className="container-fuild">
            <div className="row">
                <div className="col-6">
                    <button className="third-party-btn" style={arabicLang ? {padding: "16px 10px"} : {}} onClick={() => handleGoogleProvider()}>
                        <img src={googleIcon} alt="icon"/>
                        <span style={arabicLang ? {fontFamily: "dubai-bold"} : {fontFamily: "gliroy-bold"}}>{t("button.third_party.1")}</span>
                    </button>
                </div>
                <div className="col-6">
                    <button className="third-party-btn" style={arabicLang ? {padding: "16px 10px"} : {}} onClick={() => handleAppleProvider()}>
                        <img src={appleIcon} alt="icon" />
                        <span style={arabicLang ? {fontFamily: "dubai-bold"} : {fontFamily: "gliroy-bold"}}>{t("button.third_party.2")}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}
