import "./search-style.css";
import {Link} from "react-router-dom";
// images
import search_icon from "../../img/svg/search_icon.svg";
import map_icon from "../../img/svg/map.svg";
import calendar_icon from "../../img/svg/calendar.svg";
import medal_star_icon from "../../img/svg/medal_star_icon.svg";
import search_now_arrow from "../../img/svg/search_now_arrow.svg";

import { useTranslation } from "react-i18next";

export default function SearchBarLight() {
  const [t, i18n] = useTranslation();
  const arabicLang = (i18n.language == "ar");

  return (
  <div className='search-bar-light'>
    <div className="container">
      <div className="search-bar-box search-bar-light-box">
        <h2 className={`search-bar-title ${arabicLang ? "width-with-font" : "main-title"}`}>{t("page.home.section.title.search")}</h2>
        <div className="search-form">
          <div className={`search-icon d-none d-lg-inline-flex ${arabicLang ? "border-l" : ""}`}>
            <img src={search_icon} alt="search icon" />
          </div>
          <div className={`row ${arabicLang ? "ms-auto" : ""}`}>
            <div className={`col-md-3 col-lg-3 col-xl-2 offset-xl-1 ${arabicLang ? "offset-xl-1-rtl" : ""}`}>
              <div className="group-input">
                <img src={map_icon} alt="icon" />
                <input type="text" className={`form-control ${arabicLang ? "paddingRight-with-font" : ""}`} placeholder={t("input.placeholder.city")}/>
              </div>
            </div>
            <div className={`col-md-3 col-lg-3 col-xl-2 offset-xl-1 ${arabicLang ? "offset-xl-1-rtl" : ""}`}>
              <div className="group-input">
                <img src={calendar_icon} alt="icon" />
                <input type="text" className={`form-control ${arabicLang ? "paddingRight-with-font" : ""}`} placeholder={t("input.placeholder.day")}/>
              </div>
            </div>
            <div className={`col-md-3 col-lg-3 col-xl-2 offset-xl-1 ${arabicLang ? "offset-xl-1-rtl" : ""}`}>
              <div className="group-input">
                <img src={medal_star_icon} alt="icon" />
                <input type="text" className={`form-control ${arabicLang ? "paddingRight-with-font" : ""}`} placeholder={t("input.placeholder.speciality")}/>
              </div>
            </div>
            <div className="col-md-3">
              <div className="search-btn-content">
                <Link to="/search" className="search-now-btn">
                  <span>{t("button.search_now")}</span>
                  <span className="d-none d-lg-flex" ><img src={search_now_arrow} alt="icon" style={(i18n.language == "ar") ? {transform: "rotate(180deg)", marginRight: "8px", marginLeft: "0"} : {}}/></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
