import "./carouselStyle.css";
import { Carousel } from "react-bootstrap";
// images
import carouselArrowLeft from "../../img/svg/carousel_arrow_left.svg";
import carouselArrowRight from "../../img/svg/carousel_arrow_right.svg";
import userRemoveIcon from "../../img/svg/user_remove_icon.svg";
import coinIcon from "../../img/svg/coin_icon.svg";
import noteIcon from "../../img/svg/note_icon.svg";
import { useTranslation } from "react-i18next";

export default function PracticeCarousel() {
    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language == "ar");

    return (
    <Carousel id="practice-carousel" indicators={false} prevIcon={<span><img src={carouselArrowLeft} alt="icon" /></span>} nextIcon={<span><img src={carouselArrowRight} alt="icon" /></span>}>
        <Carousel.Item>
            <div className="container">
                <div className="row text-center">
                    <div className="col-lg-4">
                        <div className="practice-box" style={arabicLang ? {textAlign: "right"} : {}}>
                            <div className="img-content">
                                <img src={coinIcon} alt="icon" />
                            </div>
                            <h4 className={`practice-box-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("carousel.practice.box1.title")}</h4>
                            <p className={`practice-box-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("carousel.practice.box1.desc")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="practice-box" style={arabicLang ? {textAlign: "right"} : {}}>
                            <div className="img-content">
                                <img src={noteIcon} alt="icon" />
                            </div>
                            <h4 className={`practice-box-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("carousel.practice.box2.title")}</h4>
                            <p className={`practice-box-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("carousel.practice.box2.desc")}</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="practice-box" style={arabicLang ? {textAlign: "right"} : {}}>
                            <div className="img-content">
                                <img src={userRemoveIcon} alt="icon" />
                            </div>
                            <h4 className={`practice-box-title ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("carousel.practice.box3.title")}</h4>
                            <p className={`practice-box-desc ${arabicLang ? "ge-two-light" : "para-regular-font"}`}>{t("carousel.practice.box3.desc")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Carousel.Item>
    </Carousel>
    );
}