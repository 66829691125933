import "./SearchPageStyle.css";
// components
import TopNavbar from "../../components/navbar/TopNavbar";
import Footer from "../../components/footer/Footer";
import SectionTitle from "../../components/section/SectionTitle";
import SearchBarDark from "../../components/searchBar/SearchBarDark";
import AttorneyCard from "../../components/card/AttorneyCard";
import ScrollbarDark from "../../components/scrollbar/ScrollbarDark.tsx";
import { useTranslation } from "react-i18next";

// images
import locationIcon from "../../img/svg/location.svg";
import awardIcon from "../../img/svg/award_dark.svg";
import attorneyImg1 from "../../img/attorney_img1.png";
import attorneyImg2 from "../../img/attorney_img2.png";
import attorneyImg3 from "../../img/attorney_img3.png";

export default function SearchPage() {

  const [t, i18n] = useTranslation();
  const arabicLang = (i18n.language === "ar");

  return (
    <ScrollbarDark >
      <div className="search-page">

          <div className="header-padding">
              <div className="dark-header header" style={arabicLang ? {background: "linear-gradient(220.5deg, #37363E 0.63%, #424149 28.44%, #2B2B31 56.78%, #111114 81.51%)"} : {}}>
                <TopNavbar mode="navbar-dark-mode"/>

                <div className="container">
                    <div className="title-content">
                      <h1 className={`header-title color-dark-mode ${arabicLang ? "dubai-bold" : "main-title"}`}>{t("page.search.section.title.main")}</h1>
                      <p className={`section-desc color-dark-moded-desc ${arabicLang ? "ge-two-light" : "main-title"}`}>{t("page.search.section.description.main")}</p>
                    </div>
                    <SearchBarDark />
                </div>
              </div>
          </div>

          <main>
            <section id="search-result">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <SectionTitle hint={t("page.search.section.hint.search_result")}
                              title={t("page.search.section.title.search_result")} />
                  </div>
                </div>
                <div className="attorneys-content">
                  <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <AttorneyCard
                            imgSrc={attorneyImg1}
                            attorneyName={t(`carousel.attorneys.attorney1.name`)}
                            attorneyInfo1={<div><img src={awardIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney1.practice`)}</span></div>}
                            attorneyInfo2={<div><img src={locationIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney1.location`)}</span></div>}
                        />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <AttorneyCard
                            imgSrc={attorneyImg2}
                            attorneyName={t(`carousel.attorneys.attorney2.name`)}
                            attorneyInfo1={<div><img src={awardIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney2.practice`)}</span></div>}
                            attorneyInfo2={<div><img src={locationIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney2.location`)}</span></div>}
                        />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <AttorneyCard
                            imgSrc={attorneyImg3}
                            attorneyName={t(`carousel.attorneys.attorney3.name`)}
                            attorneyInfo1={<div><img src={awardIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney3.practice`)}</span></div>}
                            attorneyInfo2={<div><img src={locationIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney3.location`)}</span></div>}
                        />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <AttorneyCard
                            imgSrc={attorneyImg1}
                            attorneyName={t(`carousel.attorneys.attorney1.name`)}
                            attorneyInfo1={<div><img src={awardIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney1.practice`)}</span></div>}
                            attorneyInfo2={<div><img src={locationIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney1.location`)}</span></div>}
                        />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <AttorneyCard
                            imgSrc={attorneyImg2}
                            attorneyName={t(`carousel.attorneys.attorney2.name`)}
                            attorneyInfo1={<div><img src={awardIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney2.practice`)}</span></div>}
                            attorneyInfo2={<div><img src={locationIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney2.location`)}</span></div>}
                        />
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <AttorneyCard
                            imgSrc={attorneyImg3}
                            attorneyName={t(`carousel.attorneys.attorney3.name`)}
                            attorneyInfo1={<div><img src={awardIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney3.practice`)}</span></div>}
                            attorneyInfo2={<div><img src={locationIcon} alt="icon" style={arabicLang ? {marginRight: "0", marginLeft: "5px"} : {}}/><span style={arabicLang ? {marginRight: "0", marginLeft: "20px"} : {}}>{t(`carousel.attorneys.attorney3.location`)}</span></div>}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Footer />
      </div>
    </ScrollbarDark>
  );
};