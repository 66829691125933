import "./sectionTitleStyle.css";
import { useTranslation } from "react-i18next";

export default function SectionTitle({hint, title}) {
  const [t, i18n] = useTranslation();

  return (
      <div className="section-header">
          <span className={`section-subtitle ${i18n.language == "ar" ? "ge-two-bold" : "hint-bold-font"}`}>{hint}</span>
          <h4 className={`section-title ${i18n.language == "ar" ? "font-with-width" : "main-title"}`}>{title}</h4>
      </div>
  );
}
