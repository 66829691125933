import "./formStyle.css";
import TopNavbar from "../../components/navbar/TopNavbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function CodePage({setRandomCode, randomCode}) {

    const [t, i18n] = useTranslation();
    const arabicLang = (i18n.language === "ar");
    const navigate = useNavigate();
    const [code, setCode] = useState([]);
    const [errorMsg, setErrorMsg] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();
        const digitCode = parseInt(code.join(""));
        if (digitCode == randomCode) {
            navigate("/");
        } else {
            setErrorMsg(t("alertMsg.code.wrong"));
            setCode([]);
            for (let i = 0; i < 6; i++) {
                e.target[i].value = "";
            }
        }
    }

    // useEffect(() => {
    //     console.log(randomCode);
    // }, [randomCode]);

    // const handleResendCode = useCallback(() => {
    //     setRandomCode(Math.floor(Math.random() * (999999 - 100000) + 100000));
    // }, [randomCode])

    return(
        <div className="auth-page">
            <div className="header-padding">
                <div className="dark-header">
                    <TopNavbar mode="navbar-dark-mode"/>

                    <div className="auth-form-content">
                        <div className="auth-form">
                            <div className="content">
                                <div>
                                    <h1 className={`form-title ${arabicLang ? "dubai-bold" : "main-title"}`} style={arabicLang ? {marginTop: "35px", maxWidth: "90%"} : {}}>{t("page.auth.title.check")}</h1>
                                </div>
                                
                                <div>
                                    <p className={`form-desc ${arabicLang ? "ge-two-light" : "main-title"}`}>{t("page.auth.desc.check")} <span style={{color: "#D1AB70"}}>Davis@lawayer.net</span> {t("page.auth.desc.check2")}
                                    </p>
                                </div>

                                {errorMsg && <span className={`errorMsg ${i18n.language == "ar" ? "ge-two-light" : "para-regular-font"}`}>{errorMsg}</span>}
                                <form className="group-form-content" onSubmit={(e) => handleSubmit(e)}>
                                    <div className="digits-content">
                                        {[1,2,3,4,5,6].map(digit => (
                                            <input onChange={(e) => setCode(prevCode => [...prevCode, e.target.value])} key={digit} type="text" className="digit" maxLength="1" name={`opt${digit}`} onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault();}}} style={arabicLang ? {fontFamily: "ge-two-bold"} : {fontFamily: "gliroy-semibold"}} required/>
                                        ))}
                                    </div>
                                    <button className="submit-btn" style={arabicLang ? {fontFamily: "dubai-bold"} : {fontFamily: "gliroy-semibold"}}>{t("button.enter")}</button>
                                </form>

                                <div><span id="resend-code" className="bottom-hint" style={arabicLang ? {fontFamily: "ge-two-light"} : {fontFamily: "gliroy-semibold"}}>{t("page.auth.hint.check")}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}